import * as React from 'react'

import CTA from '../../../components/organisms/CTA'
import CasesRelational from '../../../components/organisms/CasesRelational'
import Seo from '../../../helpers/seo'
import Layout from '../../../templates/layout'
import Content from './content'
import jwBlondeJson from './jw-blonde.json'

import Banner from '../../../components/molecules/Banner'

const JwBlonde = () => {
  const { relational } = jwBlondeJson

  return (
    <Layout page="Johnnie Walker Blonde">
      <Banner image="banner-jw-blonde.webp" />
      <Content />
      <CTA />
      <CasesRelational relationalData={relational} itsRelationalCase />
    </Layout>
  )
}
export const Head = () => <Seo title="Johnnie Walker Blonde" />

export default JwBlonde
